/* * {
  margin: 0px;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;

}


.heading {
  display: flex;
  background-color: #232f3e;
  box-shadow: 0px 1px 2px #232f3e;

}

h1 {
  color: coral;
  font-weight: bold;
  
  background: transparent;
  padding: 7px;
  
} */


.outer-wrapper {

  /* display: flex; for display check index.css hide-less-500 */
  /* Overrides .App align-items center which makes the tables overflow */
  align-self: stretch; 
  justify-content: center;
  /* margin: 5px; */
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  border: 1px dotted black;
  /* border-radius: 4px; */
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.9); */
  /* max-width: fit-content; */
  max-height: fit-content;  
}

@media only screen and (min-width: 1100px) {
  .outer-wrapper {
      border: none;
  }
}


.table-wrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  height: fit-content;
  max-height: 66.4vh;
  
  margin-top: 22px;
  
  margin: 5px;
  padding-bottom: 20px;
  
}


table {
  
  /* min-width: max-content; */
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0px;    
  
}



table th{
  position: sticky; 
  top: 0px;
  
  background-color: var(--color0);
  color: var(--color1);
  
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  /* outline: 0.7px solid black; */
  /* border: 1.5px solid black; */
z-index: 1;
} 



table th, table td {
  
   padding: 15px; 
  padding-top: 10px; 
 padding-bottom: 10px;
  
}

table td {
  text-align: center;
  
  font-size: 15px;
  /* border: 1px solid rgb(177, 177, 177); */
  /* padding-left: 20px; */
  
}