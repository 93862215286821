.bars-plus-navbar {
/* display: flex;
flex-direction: row; */
position: absolute;
width: 100%;
background-color: black;
}

.bars {
  margin-left: 15px;
  position: fixed;
  z-index: 99999;
}

/* overrrides _navbar.scss:19 don't know where that is */
.navbar {
padding: 0;
padding-left: 1vw;
/* padding-right: 1vw; */
justify-content: center;

}

.navbar-link {
  color: var(--color2);
  /* width: 11%; */
}

.navbar-link:visited {
  color: var(--color2)
}

.no-decoration {
  text-decoration: none;
}

.no-decoration:hover {
  text-decoration: none;
}

.navbar-link:hover {
  color: var(--color1);
}

.navbar-small {
  display: flex;
  flex-direction: row;
  margin: 0vw 10vw 0vw 10vw;
justify-content: space-evenly;
}

.navbar-big {
  display: flex;
  flex-direction: row;
  margin: 0vw 10vw 0vw 10vw;
  justify-content: space-between;
}



@media only screen and (max-width: 499px) {
/* The side navigation menu */
.sidenav {
  position: fixed;
  height: 100%; /* 100% Full-height */
  /* width: 0; 0 width - change this with JavaScript */
  /* position: absolute; */
  width: 15%;
  z-index: 2; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: black; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 10px; 
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  
}

/* The navigation menu links */
.sidenav a {
  padding: 6px 16px 40px 16px;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}


  .navbar-small {
    display: none;
  }
  .navbar-big {
    display: none;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1100px) {
  .sidenav{
    display: none;
  }

  .navbar-small {
    display: flex;
  }

  .navbar-big {
    display: none;
  }
}


@media only screen and (min-width:1101px) {
  
  .sidenav{
    display: none;
  }

  .navbar-big {
    display: flex;
  }
  .navbar-small {
    display: none;
  }

}