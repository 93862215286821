@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

:root {
    --color0: black;
    --color1: white;
    --color2: lightgrey;
    --color3: red;
    --color4: darkgreen;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    color: #fff;
}

body {
    min-height: 100vh;
    /* background-color: dodgerblue; */
    background-color: var(--color0);
}

.App {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
}

.no-media-file {
    background-color: black;
    color: white;
    font-size: 70%
}


@media only screen and (max-width: 500px) {
    .App {
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

        margin-left: 50px;
        padding: 1rem;
    }

    .no-media-file {
        font-size: 50%
    }

}

.btn {
    white-space: none;
}

.bars-plus-navbar {
    min-height: 5vh;
}

.section-one-step-view {
    max-width: 60%;
}



.section-home {
    width: 95%;
    /* margin: 1em; */
    max-width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: var(--color2);
    border-radius: 20px;
}

section {
    width: 95%;
    /* margin: 1em; */
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: var(--color2);
    border-radius: 20px;
}

.section-steps {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 30%;

    
}

.section-admin {
    /* max-width: 420px;
    min-height: 400px; */
    /* max-width: 75%; */
    min-width: 90%;
    margin: 0px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

.admin-items-group {
    /* width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
}

.admin-items {
  display: flex;
  flex-direction: row;
  text-align: start;
  margin: 10px;
  width: 100%;
}

.admin-items-name {
 width: 100%;
}

.searchbar {
    text-align: center;
}

.searchbar-search-button {
    margin: 0px;
    padding: 5px;
}

.searchbar-text {
    width: 70%;
}

@media only screen and (max-width: 900px) {
    .admin-items-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
        
}



/* a, a:visited {
    color: #fff;
} */

.modal-no-white-link {
    color: var(--color0);
}

input[type="text"],
input[type="password"],
button,
textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
}

label,
button {
    margin-top: 1rem;
}

button {
    padding: 0.5rem;
}

li {
    list-style-type: none;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: var(--color0);
    color: var(--color1);
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: var(--color3);
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}


.persistCheck {
    font-size: 0.75rem;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.persistCheck label {
    margin: 0;
}

[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin: 0 5px 2px 2px;
    cursor: pointer;
}

/* dev */

.home-section {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
}

.home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-body {
    margin-top: 1em;
    text-align: center;
}

.home-footer {
    margin-top: 50px;
    font-size: 10px;
}

@media only screen and (min-width: 500px) {
    .home-footer {

        font-size: 14px;
    }
}



.footerp {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* margin-bottom: auto; */
}

.home-ext-link {
    text-decoration: none;
    color: var(--color0);
}

.section-bigger {
    /* width: 95%; */
    margin: 1em;
    max-width: 70%;
    /* min-width: 200px; */
    /* min-height: 90%; */
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
}

.close-one-view {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: 0px
}


.delete-selection {
    background-color: transparent;
    border: none;
    color: var(--color3);
    cursor: pointer;
    margin-top: 0px;
}

.delete-selection-recycling-bin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* min-width: 200px; */
}

.add-step-button {
    max-width: 250px;
    margin-bottom: 20px;
}



.modal-content {
    background-color: var(--color1);
    border: none;
}

.one-step-modal {
    /* background-color: transparent; */
    background-color: var(--color2);
}

.one-step-view {
    width: 100%;
    text-align: center;
}

.one-step-view-header, .one-step-view-audio, .one-step-view-video {
    margin: 20px 0px 20px 0px;
}

.one-step-view-header {
    font-size: 100%;
}

.audio {
    max-width: 100%;
}


.modal-header {
    border-bottom: 0px;
}

.modal-footer {
    border-top: 0px;
}

.alt-word {
    color: var(--color0);
    font-size: 16px;
}

/* .pad-lef-75 {
    padding-left: 75px;
} */

.link-black {
    color: var(--color0)
}

.link-black:visited {
    color: var(--color0);
    text-decoration: none;
}


@media only screen and (max-width: 350px) {
    .video {
        /* height: 100px; */
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    .home-body {
        font-size: 75%;
    }



}

@media only screen and (min-width: 351px) and (max-width: 999px) {
    .video {
        /* height: 75%; */
        height: 100%;
        width: 100%;
        border-radius: 20px;
    }

    .home-body {
        font-size: 85%;
    }

}


@media only screen and (min-width: 1000px) {
    .hide-more-1000 {
        display: none;
    }

    .section-bigger {
        max-width: 50%;
        /* max-height: 80%; */
    }

    .home-logo-img {
        max-width: 375px;
    }

    .home-section {
        max-width: 20px;
        min-height: 700px;
    }

    .video {
        height: 100%;
        max-width: 100%;
        border-radius: 20px;
        object-fit: contain
    }

    /* width: 100%;
    margin-top: 1em;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
   */

}

@media only screen and (max-width: 1000px) {
    .hide-less-1000 {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .hide-less-700 {
        display: none;
    }
}

.level-title, .level-title-smaller {
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .level-title {
        font-size: 125%;
        font-weight: 500;
    }

    .level-title-smaller {
        font-size: 100%;
    }

    .show-less-500 {
        display: flex;
        background-color: lightgreen;
        color: var(--color4);
        font-weight: bold;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        text-align: center;
        font-size: 50%;
    }

    .hide-less-500 {
        display: none;
    }   

    .section-one-step-view {
        max-width: 420px;


    }
}

@media only screen and (min-width: 500px) {
    .show-less-500 {
        display: none;
    }

    .hide-less-500 {
        display: flex;
    }

    .one-step-view-header {
        font-size: 150%;
    }
}


.btn-no-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 0px;
}

.btn-no-btn:focus {
    outline: none;
}

.title-pointer {
    cursor: pointer;
}

.step-title-big {
    font-size: 1em;
}

.x-add-step-header {
    align-self: end;
    cursor: pointer;
    color: var(--color0);
}

.x-add-step-header:hover {
    text-decoration: none;
    color: var(--color0);
}

.button-black {
    background-color: var(--color0);
    color: var(--color1)
}

.edit-item {
    font-size: 24px;
    padding-right: 5px;
}

.--wider {
    width: 90%
}

.form-control-add {
    margin-top: 30px;
    font-size: 24px;
}

.--less-margin-top {
    margin-top: 10px;
}

.--margin-bottom-100px {
    margin-bottom: 100px;
}

.--fl-dir-col {
    display: flex;
    flex-direction: column;
}

.modal-step-title {
    font-size: 34px;
}

.media-alt {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* padding-left: 20px;
    padding-top: 5px; */
}

.margin-left-10px {
    margin-left: 10px;
}

.--margin-10px {
    margin: 10px;
}

.--background-grey {
    background-color: var(--color2);
}

.--margin-bottom-20 {
    margin-bottom: 20px;
}

.rec-bin-item {
    padding-top: 25px;
    font-size: 24px;
}

.center-empty-bin {
    text-align: center;
}

.text-align-center {
    text-align: center;
}

.font-size-22 {
    font-size: 22px;
}

.scsmsg {
    background-color: lightgreen;
    color: var(--color4);
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

.padding-0 {
    padding: 0px;
}

.center-small-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin-top-2em {
    margin-top: 2em;
}

.margin-top-0 {
    margin-top: 0px
}

.home-logo-img {
    width: 75%;
    max-width: 375px;
}


@media only screen and (max-width: 500px) {

    .user-grid {
        display: flex;
        flex-direction: column;
    }

    .one-user-grid {
        margin: 20px 0px 20px 0px;
    }

}

.upload-file {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.check {
    width: 20%;
}

#uploadForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
}

#myFiles {
    width: 100%;
}

.upload-form-and-icon {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 450px) {
    .upload-form-and-icon {
        flex-direction: column;
    }
}

.upload-success {
    text-align: center;
}

.upload-icon {
    cursor: pointer;
}


/* dev-end */

/* Tooltip text

<div className="tooltip">Hover over me
  <span className="tooltiptext">Tooltip text</span>
</div>

*/


/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: relative;
    z-index: 1;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* Songs styles modules */



/*





*/